<template>
  <div class="content">
    <div class="main">
      <div class="title"></div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="phone"
          name="phone"
          label="手机号"
          placeholder="手机号"
          :rules="[{ required: true, message: '请填写手机号' }]"
        />
        <van-field
          v-model="agency_name" @click="isagencyshow=true" readonly
          label="选择领奖机构" :rules="[{ required: true, message: '请选择领奖图书馆/机构' }]"
          placeholder="请选择领取奖品的图书馆/机构"
        />
        <div class="bot-btn">
          <van-button class="btn" round block native-type="submit">提交</van-button>
        </div>
      </van-form>
      <van-action-sheet v-model="isagencyshow" title="选择领取奖图书馆">
        <van-search
        v-model="keyword"
        show-action
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="onSearch" @enter="onSearch">搜索</div>
        </template>
      </van-search>
        <van-picker :default-index="default_index"
          title="" :columns-field-names="{ text: 'agency_name', value: 'agency_guid' }"
          show-toolbar @confirm="onConfirm" @cancel="isagencyshow=false"
          :columns="columns"
        />
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { editinfo } from '@/api/Cuser'
import { getagencys } from '@/api/Activity.js'
import { Toast } from 'vant'
import { setagency } from '@/api/Questions.js'
import { setagencyco } from '@/api/Collects.js'
import { setagencysign } from '@/api/SignIn.js'
export default {
  name: 'EditUserinfoAgency',
  props: ['activity', 'c_user_phone', 'c_user_name'],
  components: {
  },
  computed: {
    ...mapGetters(['agencyGuid', 'userGuid'])
  },
  data () {
    return {
      showOverlay: false,
      username: this.c_user_name,
      phone: this.c_user_phone,
      agency_guid: '',
      agency_name: '',
      isagencyshow: false,
      keyword: '',
      columns: [],
      default_index: 0
    }
  },
  mounted () {
    this._getagencys()
  },
  watch: {
    columns (newval) {
      newval.forEach((element, index) => {
        if (this.agencyGuid === element.value) {
          this.default_index = index
          this.agency_guid = element.value
          this.agency_name = element.text
        }
      })
    }
  },
  methods: {
    onSearch (e) {
      this._getagencys()
    },
    _getagencys () {
      getagencys({ keyword: this.keyword }).then(res => {
        if (res.code === 200 && res.data) {
          this.columns = res.data
        }
      })
    },
    onConfirm (e) {
      this.agency_guid = e.value
      this.agency_name = e.text
      this.isagencyshow = false
    },
    onSubmit (values) {
      this._editinfo()
      if (this.activity.activity_type_new === 3) {
        this._setagency()
      }
      if (this.activity.activity_type_new === 2) {
        this._setagency2()
      }
      if (this.activity.activity_type_new === 1) {
        this._setagency1()
      }
    },
    _setagency () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setagency({ c_user_guid: this.userGuid, agency_guid: this.agency_guid, activity_guid: this.activity.activity_guid }).then(res => {
        if (res.code === 200) {
          Toast.success(res.msg)
          this.$emit('getuseragency')
        }
      })
    },
    _setagency2 () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setagencyco({ c_user_guid: this.userGuid, agency_guid: this.agency_guid, activity_guid: this.activity.activity_guid }).then(res => {
        if (res.code === 200) {
          Toast.success(res.msg)
          this.$emit('getuseragency')
        }
      })
    },
    _setagency1 () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setagencysign({ c_user_guid: this.userGuid, agency_guid: this.agency_guid, activity_guid: this.activity.activity_guid }).then(res => {
        if (res.code === 200) {
          Toast.success(res.msg)
          this.$emit('getuseragency')
        }
      })
    },
    _editinfo () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      editinfo({ c_user_guid: this.userGuid, c_user_phone: this.phone, c_user_name: this.username }).then(res => {
        if (res.code === 200) {
          Toast.success(res.msg)
          this.$emit('getCUserInfoNew')
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.content{
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  z-index 10000
  background rgba(0, 0, 0, .7)
}
.main{
  padding:30px;
  background-color:#ffffff;
  width: calc(100% - 160px);
  height: 775px;
  background: #FFFFFF;
  border-radius: 24px 0px 24px 0px;
  margin-left:auto;
  margin-right:auto;
  margin-top:210px;
  box-shadow: 0px 5px 10px #eeeeee;
  position relative
  .title{
    height:36px;
    line-height:100px;
    wdith:100%;
    text-align:center;
    background-image:url('~@/assets/img/activity/edit-info-title.png')
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
    margin-bottom:58px;
    margin-top:42px;
  }
  .van-cell{
    line-height:30px;
  }
  .bot-btn{
    position:absolute;
    bottom:70px;
    width:calc(100% - 90px);
    text-align:center;
    padding:0 30px;
    .btn{
      width: 532px;
      height: 88px;
      background: #BE0404;
      box-shadow: 0px 4px 13px 0px rgba(190,4,4,0.47);
      border-radius: 44px;
      color:#ffffff;
      border:none;
    }
  }
}
</style>
